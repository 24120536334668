<template>
  <div :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <!-- <div style="height: 25px; background: #fff"></div>
    <div class="head">
      地址
      <img
        class="left"
        @click="$router.back(-1)"
        src="../../../assets/ShopImg/left.png"
        alt=""
      />
    </div> -->
    <template v-if="isWxApplets">
      <van-nav-bar
        title="添加地址"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <van-address-edit
      :area-list="areaList"
      :address-info="addressInfo"
      tel-maxlength="11"
      :tel-validator="validator"
      show-delete
      show-set-default
      show-search-result
      @save="saveAddress"
      @delete="deleteAddress"
    />
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import { editUserAddress, deleteUserAddress } from "@/api/shop";
import { Toast } from "vant";
export default {
  data() {
    //  return { areaList };
    return {
      areaList: areaList,
      userId: 0,
      userInfo: {},
      addressInfo: {
        id: "",
        name: "",
        tel: "",
        province: "",
        city: "",
        county: "",
        addressDetail: "",
        isDefault: "",
        areaCode: "",
      },
      goodsData: "",
      isWxApplets: true,
    };
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "添加地址";
      this.isWxApplets = false;
    }
  },
  mounted() {
    if (localStorage.getItem("userInfo") != null) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.userId = this.userInfo.Id;
    } else {
      this.userInfo.Id = 0;
    }
    if (this.$route.query.addressData) {
      this.addressData = JSON.parse(this.$route.query.addressData);
      this.addressInfo.id = this.addressData.Id;
      this.addressInfo.name = this.addressData.ContactName;
      this.addressInfo.tel = this.addressData.ContactPhone;
      this.addressInfo.province = this.addressData.Province;
      this.addressInfo.city = this.addressData.City;
      this.addressInfo.county = this.addressData.Area;
      this.addressInfo.addressDetail = this.addressData.Address;
      this.addressInfo.isDefault =
        this.addressData.IsDefault == 1 ? true : false;
      this.addressInfo.areaCode = this.addressData.AreaCode;
    }
    this.goodsData = this.$route.query.goodsData;
  },
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    async deleteAddress(content) {
      console.log(content);
      const res = await deleteUserAddress(content.id);
      if (res.success == true) {
        const toast = Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "删除成功",
        });

        let second = 1;
        const timer = setInterval(() => {
          second--;
          if (second) {
            console.log("qqq");
          } else {
            clearInterval(timer);
            // 手动清除 Toast
            Toast.clear();
            this.$router.push({
              path: "/shop/address/index",
            });
          }
        }, 1000);
      } else {
        Toast.fail("删除失败，请重试");
      }
    },
    async saveAddress(content) {
      let params = {};
      params.Id = content.id == "" ? 0 : content.id;
      params.UserId = this.userId;
      params.Province = content.province;
      params.City = content.city;
      params.Area = content.county;
      params.ZipCode = content.postalCode;
      params.ContactName = content.name;
      params.ContactPhone = content.tel;
      params.Address = content.addressDetail;
      params.AreaCode = content.areaCode;
      params.IsDefault = content.isDefault ? 1 : 0;
      const res = await editUserAddress(params);
      if (res.success == true) {
        Toast.success("保存成功");
        this.$router.push({
          path: "/shop/address/index",
          query: {
            goodsData: this.goodsData,
          },
        });
      } else {
        Toast.fail("修改失败，请重试");
      }
    },
    validator(e) {
      console.log(e);
      let a = /^1[3-9]\d{9}$/;
      if (!a.test(e)) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.head {
  height: 50px;
  line-height: 50px;
  width: 100%;
  text-align: center;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  background: #fff;

  .left {
    width: 22px;
    height: 17px;
    position: absolute;
    left: 4%;
    top: 50%;
    transform: translateY(-50%);
  }
}

/deep/ .van-button--danger {
  background: linear-gradient(99deg, #000000 0%, #000000 100%);
  border: none;
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
